import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const TermsPartner = ({ locale, title, classNames}) => {
  const src = "/terms/termos-e-condicoes-parceiros.html";
  return (
    <div class="iframe__container"> 
        <iframe
            class={`iframe__responsive${classNames ? ` ${classNames}` : ''}`}
            src={src}
            title={`${title ? `${title}` : ''}`}
        />
    </div>
  );
};

TermsPartner.propTypes = {
  classNames: PropTypes.string,
  locale: PropTypes.string,
  title: PropTypes.string
};

TermsPartner.defaultProps = {
  classNames: null,
  locale: null,
  title: null
};

export default TermsPartner;
