import React from 'react';
import Moment from 'moment';

import Layout from 'components/layout';
import LegalLayout from 'components/legal-layout';
import SEO from 'components/seo';
import TermsPartner from '@youship/components/terms-partner';

const lastModifiedDate = Moment().format('MMMM DD, YYYY');

const TermsAndConditionsTransporterPage = ({ pageContext: { locale } }) => {

  return (
    <Layout locale={locale}>
      <SEO title="footer.copyright.links.terms_and_conditions" />
      <LegalLayout
        lastModifiedDate={lastModifiedDate}
        title="footer.copyright.links.terms_and_conditions"
      >
      <TermsPartner
            locale={locale}
      />
      </LegalLayout>
    </Layout>
  );
};


export default TermsAndConditionsTransporterPage;
